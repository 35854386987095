import * as React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { ChevronDoubleLeftIcon, ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid'


const ProjectListTemplate = ({ data, pageContext, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const projects = data.allMarkdownRemark.nodes
  const { currentPage, numPages } = pageContext
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage = currentPage - 1 === 1 ? "/work" : `/work/${currentPage - 1}`
  const nextPage = `/work/${currentPage + 1}`

  const parseMarkdown = (text) => {
    return text.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')
  }
  
  const renderPagination = () => {
    let items = []
    const maxPagesToShow = 4

    if (currentPage > 1) {
      items.push(
        <Link key="first" to="/work/" className="relative m-0 inline-flex items-center border border-gray-800 px-4 py-2 text-sm font-medium">
          <ChevronDoubleLeftIcon className="h-5 w-5" aria-hidden="true" />
        </Link>
      )
      items.push(
        <Link key="prev" to={prevPage} className="relative inline-flex items-center border-t border-b border-r border-gray-800 px-4 py-2 text-sm">
          <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
        </Link>
      )
    }

    const startPage = Math.max(1, currentPage - 2)
    const endPage = Math.min(numPages, startPage + maxPagesToShow - 1)

    for (let i = startPage; i <= endPage; i++) {
      const linkTo = i === 1 ? "/work/" : `/work/${i}`
      items.push(
        <Link key={i} to={linkTo} className={currentPage === i ? "relative z-10 inline-flex items-center p-5 px-4 py-2 text-sm border-t border-b focus:z-20 text-md first-of-type:border-l focus-visible:bg-zinc-300 border border-gray-800 border-l-0 -top-[5px] bg-black text-white" : "relative inline-flex items-center px-4 py-2 text-sm border-t border-b border-r last-of-type:border-r-0 border-gray-800 focus:z-20 -top-[5px]"}>
          {i}
        </Link>
      )
    }

    if (endPage < numPages) {
      if (endPage < numPages - 1) {
        items.push(<span key="ellipsis" className="relative inline-flex items-center px-4 py-2 text-sm border border-gray-800 focus:z-20 -top-[5px]">...</span>)
      }
      items.push(
        <Link key={numPages} to={`/work/${numPages}`} className="relative inline-flex items-center px-4 py-2 text-sm border border-gray-800 border-r-0 border-l-0 focus:z-20 -top-[5px]">
          {numPages}
        </Link>
      )
    }

    if (currentPage < numPages) {
      items.push(
        <Link key="next" to={nextPage} className="relative inline-flex items-center px-4 py-2 text-sm border border-gray-800 border-l-0 focus:z-20">
          <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
        </Link>
      )
    }

    return <div>{items}</div>
  }

  return (
    <Layout location={location} title={siteTitle}>
        <div className="py-5 px-5 lg:px-0">
          <h1 className="CyGroteskGrandBold text-3xl my-5 lg:my-5">Work</h1>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 lg:gap-10">
            {projects.map(project => {
              const title = project.frontmatter.title || project.fields.slug

              return (
                  <Link to={`/work${project.fields.slug}`} key={project.fields.slug} className="inline-block pb-5">
                    
                      {project.frontmatter.heroVideo ? (
                        <div className="overflow-hidden inline-block relative">
                        <div className="relative transition-transform transform hover:scale-110 duration-500">
                          <video playsInline muted autoPlay loop className="">
                            <source src={project.frontmatter.heroVideo} type='video/mp4' />
                          </video>
                        </div>
                        </div>
                      ) : (
                        project.frontmatter.imgSrc && (
                          <div className="overflow-hidden inline-block relative">
                            <div className="relative transition-transform transform hover:scale-105 duration-500">
                              <img
                                src={project.frontmatter.imgSrc}
                                alt={title}
                                className="w-full"
                              />
                            </div>
                          </div>
                        )
                      )}
                      <div className="flex flex-col">
                        <div className="">
                          <h2 className="CyGroteskGrandBold mt-3">{title}</h2>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: parseMarkdown(project.frontmatter.description || project.excerpt)
                            }} 
                            className="text-sm leading-5 mt-4"
                          />
                        </div>
                      </div>
                  </Link>
              )
            })}
          </div>

          <div className="mt-10 py-5 text-center">
            <div className="inline-block w-full md:w-auto md:flex sm:flex-1 md:items-center md:justify-center">
              <div className="">
                <nav className="" aria-label="Pagination">
                  <div className="">
                    {numPages > 1 && renderPagination()}
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
    </Layout>
  )
}

export const Head = () => <Seo title="Project Portfolio in AI, eCommerce, and Digital Innovation" />

export default ProjectListTemplate

export const pageQuery = graphql`
  query ProjectListQuery($skip: Int!, $limit: Int!) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: frontmatter___date, order: DESC }
      filter: { fileAbsolutePath: { regex: "/content/projects/" } }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          imgSrc
          heroVideo
        }
      }
    }
  }
`
